import React from 'react'
import { useDispatch } from 'react-redux'
import { Rnd } from 'react-rnd'

import styles from 'app/components/assistantTool/assistantToolModal/AssistantToolModal.module.scss'
import { WppTypography, WppActionButton, WppCard, WppIconClose } from 'app/components/common'
import IAiAssistantTool from 'interfaces/field/IAiAssistantTool'
import { AppDispatch } from 'store'
import { closeAssistantTool } from 'store/reducers/assistantToolSlice'

interface IAssistantToolModalProps {
  assistantTool: IAiAssistantTool
}

/**
 * Shows assistant tool modal
 */
const AssistantToolModal: React.FC<IAssistantToolModalProps> = ({ assistantTool }: IAssistantToolModalProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { name, app_url, width, height } = assistantTool

  return (
    <Rnd
      default={{
        width: `${width}px`,
        height: `${height}px`,
        x: 250,
        y: 0,
      }}
      bounds="parent"
      className={styles.container}
      enableResizing
    >
      <WppCard className={styles.card} size="l">
        <div slot="header">
          <WppTypography type="l-strong">{name}</WppTypography>
        </div>
        <div slot="actions">
          <WppActionButton
            data-testid="btn-assistant-tool-modal"
            onClick={() => dispatch(closeAssistantTool())}
            variant="secondary"
          >
            <WppIconClose slot="icon-start" />
          </WppActionButton>
        </div>
        <div className={styles.cardBody}>
          <iframe src={app_url} title={name} className={styles.iframe} />
        </div>
      </WppCard>
    </Rnd>
  )
}

export default AssistantToolModal
