import { useOs } from '@wppopen/react'
import { t } from 'i18next'
import { first, isEmpty, isEqual, orderBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'

import styles from 'app/components/categorySection/formField/formFieldAssistantTool/FormFieldAssistantTool.module.scss'
import {
  WppAccordion,
  WppActionButton,
  WppButton,
  WppIconStudio,
  WppTooltip,
  WppTypography,
} from 'app/components/common'
import IAiAssistantTool from 'interfaces/field/IAiAssistantTool'
import IField from 'interfaces/field/IField'
import { AppDispatch } from 'store'
import { closeAssistantTool, openAssistantTool } from 'store/reducers/assistantToolSlice'

interface IFormFieldAssistantToolProps {
  field: IField
}

/**
 * Shows Assistant Tools for the field
 * @param {object} props
 * @param {IField} props.field
 */
const FormFieldAssistantTool: React.FC<IFormFieldAssistantToolProps> = ({
  field,
}: IFormFieldAssistantToolProps): React.ReactElement => {
  const { osApi, osContext } = useOs()
  const dispatch = useDispatch<AppDispatch>()
  const [selectedApp, setSelectedApp] = useState<IAiAssistantTool | null>(null)

  const closeApp = () => {
    osApi.navigation.openCompactApp({
      name: selectedApp?.name ?? '',
      url: '',
      overlayProps: {
        id: 'app-toolbar-iframe',
        bounds: 'parent',
        // enableUserSelectHack: false,
        default: {
          width: 0,
          height: 0,
        },
        style: {
          display: 'none',
        },
        enableResize: true,
        cancel: '.cancel-drag',
      },
    })
    setSelectedApp(null)
  }

  const onOutsideProjectContext = () => {
    if (!osContext.appInstance?.id) {
      // @ts-ignore
      if (osApi.navigation.openCompactApp) {
        closeApp()
        return
      }
      dispatch(closeAssistantTool())
      setSelectedApp(null)
    }
  }

  useEffect(() => {
    return () => {
      onOutsideProjectContext()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osContext.appInstance?.id])

  if (!field.aiAssistantTools || isEmpty(field.aiAssistantTools) || field.disabled) return <></>

  const openApp = (assistantTool: IAiAssistantTool) => {
    setSelectedApp(assistantTool)
    if (osApi.navigation.openCompactApp) {
      const { name, app_url, width, height } = assistantTool
      osApi.navigation.openCompactApp({
        name,
        url: app_url,
        overlayProps: {
          id: 'app-toolbar-iframe',
          bounds: 'parent',
          default: {
            x: 250,
            y: 0,
            width,
            height,
          },
          style: {
            zIndex: 50,
          },
          enableResize: true,
          cancel: '.cancel-drag',
        },
      })
    } else {
      // If openCompactApp function is not available, use built-in tool viewer
      dispatch(openAssistantTool(assistantTool))
    }
  }

  // when there is only one assistant tool app
  if (isEqual(field.aiAssistantTools.length, 1)) {
    const assistantTool = first(field.aiAssistantTools) as IAiAssistantTool
    return (
      <WppTooltip
        component={
          <WppActionButton className={styles.btnAction} onClick={() => openApp(assistantTool)}>
            <div slot="icon-start" className={styles.btnIcon}>
              <WppIconStudio />
            </div>
            <Trans>app.button.inspire_me</Trans>
          </WppActionButton>
        }
        text={assistantTool.description}
        showTooltip={!isEmpty(assistantTool.description)}
      />
    )
  }

  // when there are multiple assistant tool app
  return (
    <WppAccordion size="s" className={styles.accordion}>
      <div slot="header">
        <WppTooltip
          text={t('app.tooltip.inspire_me')}
          component={
            <div className={styles.accordionHeader}>
              <div className={styles.btnIcon}>
                <WppIconStudio />
              </div>
              <WppTypography type="s-strong" className={styles.accordionTitle}>
                <Trans>app.button.inspire_me</Trans>
              </WppTypography>
            </div>
          }
        />
      </div>
      <div className={styles.accordionContent}>
        {orderBy(field.aiAssistantTools, 'order', 'asc').map((assistantTool: IAiAssistantTool) => (
          <WppTooltip
            key={assistantTool.name}
            component={
              <WppButton
                className={styles.btnAction}
                onClick={() => openApp(assistantTool)}
                variant="secondary"
                size="s"
              >
                {assistantTool.name}
              </WppButton>
            }
            text={assistantTool.description}
            showTooltip={!isEmpty(assistantTool.description)}
          />
        ))}
      </div>
    </WppAccordion>
  )
}

export default FormFieldAssistantTool
