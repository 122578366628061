// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ka2Ef{position:fixed;top:0;left:0;bottom:0;right:0;display:flex;align-items:center;justify-content:center;z-index:999999}`, "",{"version":3,"sources":["webpack://./src/app/components/common/spinner/Spinner.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CACA,KAAA,CACA,MAAA,CACA,QAAA,CACA,OAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA","sourcesContent":[".container {\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 999999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Ka2Ef`
};
export default ___CSS_LOADER_EXPORT___;
