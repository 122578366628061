// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._IAl7::part(button){color:var(--wpp-grey-color-900);border-color:var(--wpp-grey-color-900);min-width:112px}._OvOT{width:20px;height:20px}.Sd5IX{background-color:var(--wpp-grey-color-200);border-radius:8px}.Sd5IX::part(divider){display:none}.Sd5IX ._o3nM{display:flex;gap:8px;align-items:center}.Sd5IX ._o3nM .rtuGk{color:var(--wpp-text-color-info)}.Sd5IX .HqEIO{display:flex;gap:16px;margin-top:8px;flex-wrap:wrap}.dG6_0{position:fixed;top:63px;right:0;width:100%;height:calc(100% - 63px);pointer-events:none;z-index:9998;background-color:rgba(0,0,0,0)}`, "",{"version":3,"sources":["webpack://./src/app/components/categorySection/formField/formFieldAssistantTool/FormFieldAssistantTool.module.scss"],"names":[],"mappings":"AAAA,qBACE,+BAAA,CACA,sCAAA,CACA,eAAA,CAGF,OACE,UAAA,CACA,WAAA,CAGF,OACE,0CAAA,CACA,iBAAA,CAEA,sBACE,YAAA,CAGF,cACE,YAAA,CACA,OAAA,CACA,kBAAA,CAEA,qBACE,gCAAA,CAIJ,cACE,YAAA,CACA,QAAA,CACA,cAAA,CACA,cAAA,CAIJ,OACE,cAAA,CACA,QAAA,CACA,OAAA,CACA,UAAA,CACA,wBAAA,CACA,mBAAA,CACA,YAAA,CACA,8BAAA","sourcesContent":[".btnAction::part(button) {\n  color: var(--wpp-grey-color-900);\n  border-color: var(--wpp-grey-color-900);\n  min-width: 112px;\n}\n\n.btnIcon {\n  width: 20px;\n  height: 20px;\n}\n\n.accordion {\n  background-color: var(--wpp-grey-color-200);\n  border-radius: 8px;\n\n  &::part(divider) {\n    display: none;\n  }\n\n  .accordionHeader {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n\n    .accordionTitle {\n      color: var(--wpp-text-color-info);\n    }\n  }\n\n  .accordionContent {\n    display: flex;\n    gap: 16px;\n    margin-top: 8px;\n    flex-wrap: wrap;\n  }\n}\n\n.toolModalContainer {\n  position: fixed;\n  top: 63px;\n  right: 0;\n  width: 100%;\n  height: calc(100% - 63px);\n  pointer-events: none;\n  z-index: 9998;\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnAction": `_IAl7`,
	"btnIcon": `_OvOT`,
	"accordion": `Sd5IX`,
	"accordionHeader": `_o3nM`,
	"accordionTitle": `rtuGk`,
	"accordionContent": `HqEIO`,
	"toolModalContainer": `dG6_0`
};
export default ___CSS_LOADER_EXPORT___;
