// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JV83Z{z-index:50;pointer-events:all}.JV83Z .UCtk6{width:100%;height:100%}.JV83Z .UCtk6::part(card){border-radius:16px}.JV83Z .UCtk6 .v6BqK{width:100%;height:calc(100% - 58px);border-radius:var(--wpp-border-radius-l)}.JV83Z .UCtk6 .rrVKq{width:100%;height:100%}`, "",{"version":3,"sources":["webpack://./src/app/components/assistantTool/assistantToolModal/AssistantToolModal.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,kBAAA,CAEA,cACE,UAAA,CACA,WAAA,CACA,0BACE,kBAAA,CAGF,qBACE,UAAA,CACA,wBAAA,CACA,wCAAA,CAGF,qBACE,UAAA,CACA,WAAA","sourcesContent":[".container {\n  z-index: 50;\n  pointer-events: all;\n\n  .card {\n    width: 100%;\n    height: 100%;\n    &::part(card) {\n      border-radius: 16px;\n    }\n\n    .cardBody {\n      width: 100%;\n      height: calc(100% - 58px);\n      border-radius: var(--wpp-border-radius-l);\n    }\n\n    .iframe {\n      width: 100%;\n      height: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `JV83Z`,
	"card": `UCtk6`,
	"cardBody": `v6BqK`,
	"iframe": `rrVKq`
};
export default ___CSS_LOADER_EXPORT___;
