import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import IAiAssistantTool from 'interfaces/field/IAiAssistantTool'
import IAssistantToolState from 'store/interfaces/IAssistantToolState'

const defaultAlertState: IAssistantToolState = {
  assistantTool: null,
}

export const assistantToolSlice = createSlice({
  name: 'assistantTool',
  initialState: defaultAlertState,
  reducers: {
    openAssistantTool: (state: IAssistantToolState, action: PayloadAction<IAiAssistantTool>) => {
      state.assistantTool = action.payload
    },
    closeAssistantTool: (state: IAssistantToolState) => {
      state.assistantTool = null
    },
  },
})

// Action creators are generated for each case reducer function
export const { openAssistantTool, closeAssistantTool } = assistantToolSlice.actions

export default assistantToolSlice.reducer
