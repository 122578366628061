// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pCXe6{position:fixed;inset:0;top:var(--wpp-os-header-height);right:0;width:100%;height:calc(100% - var(--wpp-os-header-height));pointer-events:none;z-index:50}`, "",{"version":3,"sources":["webpack://./src/app/components/assistantTool/AssistantTool.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CACA,OAAA,CACA,+BAAA,CACA,OAAA,CACA,UAAA,CACA,+CAAA,CACA,mBAAA,CACA,UAAA","sourcesContent":[".cardContainer {\n  position: fixed;\n  inset: 0;\n  top: var(--wpp-os-header-height);\n  right: 0;\n  width: 100%;\n  height: calc(100% - var(--wpp-os-header-height));\n  pointer-events: none;\n  z-index: 50;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `pCXe6`
};
export default ___CSS_LOADER_EXPORT___;
