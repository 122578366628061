import React from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'

import styles from 'app/components/assistantTool/AssistantTool.module.scss'
import AssistantToolModal from 'app/components/assistantTool/assistantToolModal'
import { RootState } from 'store'
import IAssistantToolState from 'store/interfaces/IAssistantToolState'

/**
 * Display assistant tool
 */
const AssistantTool: React.FC = () => {
  const assistantToolState = useSelector<RootState, IAssistantToolState>((state: RootState) => state.assistantToolState)
  if (!assistantToolState.assistantTool) return <></>
  return createPortal(
    <div className={styles.cardContainer}>
      <AssistantToolModal key={assistantToolState.assistantTool.id} assistantTool={assistantToolState.assistantTool} />
    </div>,
    document.body,
  )
}

export default AssistantTool
